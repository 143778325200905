import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import useAnalysis from '@/hooks/useAnalysis'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import HalfOfYearSelector from './HalfOfYearSelector'
import MonthSelector from './MonthSelector'
import QuarterYearSelector from './QuarterYearSelector'

export enum DateSelectorMode {
    YEAR = 'year',
    MONTH = 'month',
}

export enum Mode {
    half = 'half',
    quarter = 'quarter',
    month = 'month',
}
export type DateSelectorStatus = Mode.half | Mode.quarter | Mode.month | null

const useStyle = makeStyles({
    dateSelectWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: -15,
        columnGap: 20,
        padding: '13px 15px',
        backgroundColor: theme.colors.white,
    },
})
export default (props: React.HTMLAttributes<HTMLDivElement>) => {
    const { style, ...otherProps } = props
    const { dateSelectWrapper } = useStyle()

    const { analysisState } = useStore()
    const { setAnalysisDate } = useAnalysis()

    return (
        <div id="DateSelector" className={dateSelectWrapper} style={{ ...style }} {...otherProps}>
            <div style={{ width: 224 }}>
                <HalfOfYearSelector size="sm" />
            </div>
            <div style={{ width: 224 }}>
                <QuarterYearSelector size="sm" />
            </div>
            <div style={{ minWidth: 224 }}>
                <MonthSelector size="sm" />
            </div>
            <PrimaryButton
                onClick={() => setAnalysisDate('year', analysisState.date.year)}
                width={88}
                height={35}
                fontSize={14}
            >
                リセット
            </PrimaryButton>
        </div>
    )
}
