import SelectField from '@/components/atoms/SelectField'
import useAnalysis from '@/hooks/useAnalysis'
import useStore from '@/zustand/sotre'
import React, { useMemo } from 'react'
import { SelectorProps } from './YearSelector'

function HalfOfYearSelector(props: SelectorProps) {
    const { analysisState } = useStore()
    const { dateSelector } = analysisState
    const { setAnalysisDate } = useAnalysis()

    const selectOptions = useMemo(
        () => dateSelector.options.half.map((q) => ({ id: q, value: `${q === 1 ? '上半期' : '下半期'}` })),
        [dateSelector],
    )

    function onQuarterYearSelected(half: number) {
        setAnalysisDate('half', half)
    }
    return (
        <SelectField
            size={props.size}
            value={analysisState.date.half || ''}
            onChange={(e) => onQuarterYearSelected(e.target.value as number)}
            disabled={props.disabled}
            menuItems={selectOptions}
            placeholder="半期選択"
        />
    )
}

export default HalfOfYearSelector
