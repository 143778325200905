import useStore from '@/zustand/sotre'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'

export default function SimpleSnackbar() {
    const { message, setMessage } = useStore()

    const onClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            setMessage(null)
            return
        }
        setMessage(null)
    }

    return (
        <div>
            <Snackbar
                key={message ? message.type : undefined}
                open={!!message}
                autoHideDuration={3000}
                onClose={onClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={onClose} severity={message?.type}>
                    {message?.message || ''}
                </Alert>
            </Snackbar>
        </div>
    )
}
