import theme from '@/theme'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Pagination, PaginationItem, PaginationRenderItemParams } from '@material-ui/lab'
import React from 'react'

export interface TPagination {
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    total: number
    rowPerPage: number
    withApi?: boolean
}

const useStyle = makeStyles({
    active: {
        color: '#fff',
        backgroundColor: `${theme.colors.primary} !important`,
    },
    paginationItem: {
        fontSize: 16,
        fontWeight: 500,
    },
})

export default function CustomPagination(props: TPagination) {
    const classes = useStyle()

    const handleChange = (event: object, page: number) => {
        props.setPage(props.withApi ? page - 1 : page)
    }

    const generatePagination = (params: PaginationRenderItemParams) => {
        if (params.type === 'previous')
            return (
                <Button disabled={params.disabled} onClick={params.onClick} startIcon={<KeyboardArrowLeft />}>
                    前へ
                </Button>
            )
        if (params.type === 'next')
            return (
                <Button disabled={params.disabled} onClick={params.onClick} endIcon={<KeyboardArrowRight />}>
                    次へ
                </Button>
            )
        return (
            <PaginationItem
                {...params}
                className={`${classes.paginationItem} ${params.selected ? classes.active : ''}`}
                component="div"
                variant={params.type === 'page' ? 'outlined' : 'text'}
            />
        )
    }

    return (
        <Pagination
            count={Math.ceil(props.total / props.rowPerPage) || 1}
            variant="outlined"
            shape="rounded"
            defaultPage={1}
            renderItem={(params) => generatePagination(params)}
            onChange={handleChange}
        />
    )
}
