import React from 'react'

export default function MfaIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="20" viewBox="0 0 12.5 20">
            <g id="グループ_1098" data-name="グループ 1098" transform="translate(16336 16813)">
                <path id="lock-fill" d="M9.25,1.25a2.5,2.5,0,0,1,2.5,2.5v5h-5v-5A2.5,2.5,0,0,1,9.25,1.25ZM13,8.75v-5a3.75,3.75,0,1,0-7.5,0v5A2.5,2.5,0,0,0,3,11.25V17.5A2.5,2.5,0,0,0,5.5,20H13a2.5,2.5,0,0,0,2.5-2.5V11.25A2.5,2.5,0,0,0,13,8.75Z" transform="translate(-16339 -16813)" fill="#42526e" />
                <path id="合体_5" data-name="合体 5" d="M.5,5l.678-2.035a1.5,1.5,0,1,1,.643,0L2.5,5Z" transform="translate(-16331.25 -16801)" fill="#fff" />
            </g>
        </svg>
    )
}
