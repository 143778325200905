import CustomModal from '@/components/atoms/CustomModal'
import SimpleTabs from '@/components/template/SimpleTabs'
import { notificationApi } from '@/ghgApi'
import { Notification } from '@/openapi'
import { notiApi } from '@/services/notification'
import { getTime } from '@/utils/nendo'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import React, { SetStateAction, useState } from 'react'
import { UnReadNotification } from '../Header'
import CustomPagination from '../Pagination'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badge: {
            height: 15,
            fontSize: 5,
            minWidth: 15,
            width: 15,
            borderRadius: '50%',
            top: 4,
            right: 4,
            color: theme.colors.white,
        },
        button: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: theme.colors.white,
            fontWeight: 500,
            margin: '0 5px 0',
            '&:hover': {
                background: 'transparent',
                boxShadow: 'none',
                '&:before': {
                    width: '100%',
                    padding: 0,
                },
            },
            '&::before': {
                content: '""',
                borderBottom: `1px solid ${theme.colors.white}`,
                position: 'absolute',
                bottom: 2,
                left: 0,
                right: 0,
                margin: '0 auto',
                width: 0,
                transition: '0.3s',
            },
        },
        header: {
            backgroundColor: theme.colors.black,
            minHeight: theme.header.height,
            boxShadow: '0 5px 5px -5px grey',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            width: '100%',
            height: '60px',
            zIndex: 999,
        },
        modalNoti: {
            fontFamily: theme.typography.fontFamily,
            padding: '0 15px 65px',
            minHeight: 356,
        },
        modalContent: {
            marginTop: 20,
            fontSize: 18,
        },
        notiText: {
            textDecoration: 'underline',
        },
        pagination: {
            position: 'absolute',
            bottom: 20,
            left: '50%',
            transform: 'translate(-50% , 0)',
        },
        notiItem: {
            marginTop: 10,
            fontSize: 14,
            fontWeight: 300,
        },
        notiLink: {
            color: theme.colors.primaryN,
            cursor: 'pointer',
        },
        unReadNoti: {
            fontWeight: 600,
        },
    }),
)

const NotiContent = (props: Omit<NotificationModal, 'open' | 'setOpen' | 'setYear'>) => {
    const classes = useStyles()

    const handleNotiLinkClick = async (id: number, url: string, status?: number) => {
        if (status !== 0) {
            try {
                props.setUnReadNotification((pre) => {
                    const quantity = pre.quantity - 1
                    return { status: quantity > 0 ? true : false, quantity: quantity > 0 ? quantity : 0 }
                })
                const foundNotiIndex = props.notificationList.findIndex((noti) => noti.id === id)
                if (foundNotiIndex !== -1) {
                    props.setNotificationList((pre) => {
                        const newNotiList = pre
                        newNotiList[foundNotiIndex].status = 0
                        return newNotiList
                    })
                }
                await notificationApi.updateNotificationStatus([{ id, status: 0 }])
            } catch (err) {
                console.log(err)
            }
        }
        window.open(url, '_blank')
    }

    return (
        <div className={classes.modalNoti}>
            <div className={classes.modalContent}>
                {props.notificationList.map((noti) => (
                    <Grid container key={noti.id} className={classes.notiItem}>
                        <Grid item xs={2}>
                            {noti.createdDate && new Date(noti.createdDate).toLocaleDateString('en-ZA')}
                        </Grid>
                        <Grid item xs={10} className={classes.notiText}>
                            <span
                                className={`${classes.notiLink} ${noti.status === 0 ? '' : classes.unReadNoti}`}
                                onClick={() => handleNotiLinkClick(noti.id, noti.url, noti.status)}
                            >
                                {noti.name}
                            </span>
                        </Grid>
                    </Grid>
                ))}
            </div>
            <div className={classes.pagination}>
                <CustomPagination
                    page={props.page}
                    setPage={props.setPage}
                    total={props.total}
                    rowPerPage={props.rowPerPage}
                    withApi
                />
            </div>
        </div>
    )
}

interface NotificationModal {
    open: boolean
    page: number
    rowPerPage: number
    total: number
    notificationList: Notification[]
    unReadNotification: UnReadNotification
    setYear: React.Dispatch<SetStateAction<number>>
    setPage: React.Dispatch<SetStateAction<number>>
    setNotificationList: React.Dispatch<SetStateAction<Notification[]>>
    setUnReadNotification: React.Dispatch<SetStateAction<UnReadNotification>>
    setOpen: React.Dispatch<SetStateAction<boolean>>
}
function NotificationModal(props: NotificationModal) {
    const [tabValue, setTabValue] = useState(0)
    const { year: currenYear } = getTime()

    const closeModal = () => {
        setTabValue(0)
        props.setYear(currenYear)
        props.setPage(0)
        props.setOpen(false)
    }

    const genTab = () => {
        const listYears = Array.from({ length: currenYear - 2022 }, (_, i) => i + 2023).reverse()
        return listYears.map((year, i) => ({
            label: year.toString(),
            value: i,
            content: (
                <NotiContent
                    page={props.page}
                    rowPerPage={props.rowPerPage}
                    total={props.total}
                    notificationList={props.notificationList}
                    unReadNotification={props.unReadNotification}
                    setNotificationList={props.setNotificationList}
                    setUnReadNotification={props.setUnReadNotification}
                    setPage={props.setPage}
                />
            ),
        }))
    }
    const tabs = genTab()

    const handleChange = async (value: number) => {
        if (value === tabValue) return
        const foundYear = tabs.find((t) => t.value === value)?.label
        if (foundYear) {
            props.setNotificationList([])
            const res = await notiApi.getList(undefined, +foundYear, 0, 10)
            if (res.data.data) props.setNotificationList(res.data.data)
            props.setPage(0)
            props.setYear(+foundYear)
            setTabValue(value)
        }
    }

    return (
        <CustomModal title="ScopeXに関するお知らせ" open={props.open} onClose={closeModal} width={1025} minHeight={485}>
            {tabs.length ? (
                <SimpleTabs
                    tabs={tabs}
                    value={tabValue}
                    handleChange={handleChange}
                    isSmall
                    borderBottomColor="#8F9CB4"
                />
            ) : (
                <div />
            )}
        </CustomModal>
    )
}

export default NotificationModal
