import React from 'react'

export default function ReportIcon() {
    return (
        <svg id="icon_file" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                id="Path_4169"
                data-name="Path 4169"
                d="M5.5,7a.5.5,0,0,0,0,1h5a.5.5,0,0,0,0-1ZM5,9.5A.5.5,0,0,1,5.5,9h5a.5.5,0,0,1,0,1h-5A.5.5,0,0,1,5,9.5Zm0,2a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,0,1h-2A.5.5,0,0,1,5,11.5Z"
                transform="translate(-0.5 2.545)"
                fill="#42526e"
            />
            <path
                id="Path_4170"
                data-name="Path 4170"
                d="M11.375,0H4.5A2.5,2.5,0,0,0,2,2.5v15A2.5,2.5,0,0,0,4.5,20h10A2.5,2.5,0,0,0,17,17.5V5.625Zm0,1.25v2.5A1.875,1.875,0,0,0,13.25,5.625h2.5V17.5a1.25,1.25,0,0,1-1.25,1.25H4.5A1.25,1.25,0,0,1,3.25,17.5V2.5A1.25,1.25,0,0,1,4.5,1.25Z"
                transform="translate(-2)"
                fill="#42526e"
            />
        </svg>
    )
}
