import { ROUTES } from '@/routes'
import { signOut } from '@/services/auth'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { Box, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useMemo } from 'react'

const useStyle = makeStyles({
    container: {
        width: 150,
        backgroundColor: theme.colors.white,
    },
    listItemRoot: {
        paddingBottom: 0,
        paddingTop: 0,
    },
})

export default function AccountOptions() {
    const classes = useStyle()
    const { isAdmin } = useStore()

    const options = useMemo(() => {
        const options = [{ text: 'ログアウト', action: signOut }]
        if (isAdmin) options.unshift({ text: 'ユーザー一覧', action: () => navigate(ROUTES.ACCOUNTS_SETTING) })
        return options
    }, [isAdmin])

    return (
        <Box boxShadow={3} className={classes.container}>
            <List component="nav" aria-label="main mailbox folders">
                {options.map((option, i) => {
                    return (
                        <ListItem key={i} button classes={{ root: classes.listItemRoot }}>
                            <ListItemText primary={option.text} onClick={option.action} />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}
