import { notificationApi } from '@/ghgApi'

export const notiApi = {
    getList: (search?: string, year?: number, offset?: number, limit?: number) => {
        return notificationApi.listNotifications(search || undefined, year, offset, limit)
    },
    getNew: () => {
        return notificationApi.getNewNotifications()
    },
}
