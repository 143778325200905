import React, { Dispatch, SetStateAction } from 'react'

// Key命名：page_name.variable_name （ページ名.変数名）
export enum SessionStorageKey {
    DATA_INPUT_DATE = 'data_input.date',
    ANALYSIS_DATE = 'analysis.data',
    SITE_SELECTOR = 'site.selector',
}

/**
 * 状態をSessionStorageから取得・SessionStorageに保存
 *
 * @param key SessionStorageKey
 * @param defaultState LocalStorageに状態がない場合の初期値
 */
export const useStateWithSessionStorage = <T>(
    key: SessionStorageKey,
    defaultState: T,
): [T, Dispatch<SetStateAction<T>>] => {
    const sessionItem = typeof window !== 'undefined' ? window.sessionStorage.getItem(key) : ''
    const initialState = sessionItem ? JSON.parse(sessionItem) : defaultState

    const [state, setState] = React.useState(initialState)

    React.useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state))
    }, [state])

    return [state, setState]
}
