import React from 'react'
import theme from '@/theme'
import styled from 'styled-components'
import { round, sum } from '@/utils/math'
import { GhgStackedBarProps } from './StackedBar'
import { GhgDoughnutProps } from './Doughnut'

// =================================================================================
// Types
// =================================================================================
export type ChartDataState = {
    doughnut: { [key: string]: Pick<GhgDoughnutProps, 'labels' | 'dataset'> }
    bar: { [key: string]: GhgStackedBarProps['datasets'] }
} | null

export type BarData = { label: string; data: number[]; backgroundColor: string; stack: string }

// =================================================================================
// Helper関数
// =================================================================================
export const valuesToPercentages = function (data: Array<number>): Array<number> {
    data.map((d) => console.assert(d >= 0, { data: d, message: 'data cannot be negative.' }))

    const total = sum(data)
    if (total !== 0) return data.map((d) => round((d / total) * 100))
    else return data
}

export const GettingChartDataMessage = () => (
    <div style={{ display: 'flex', justifyContent: 'center', color: theme.colors.warn }}>データ取得中…</div>
)

export const ChartFlexWrapper = styled.div`
    display: flex;
    padding: 15px 30px;
    > div.title {
        flex-grow: 0;
        white-space: nowrap;
        margin-right: 40px;
    }

    > div.graph {
        flex-grow: 1;
        overflow: hidden;

        &.doughnut {
            flex-basis: 220px;
        }

        &.bar {
            flex-basis: 420px;
        }
    }

    > div.legend {
        flex-grow: 1;
        margin-left: 45px;

        &.doughnut {
            flex-basis: 500px;
        }

        &.bar {
            flex-basis: 220px;
        }
    }
`
export namespace GhgChart {
    export namespace BackgroundColors {
        export const allScopes = ['#006FAA', '#00874B', '#C01F36']
        export const allScopesCompare = ['#006192', '#007540', '#9F1D2F']

        export const scope1Categories = ['#006FAA', '#2789BC', '#54A6CE', '#8FC7E1', '#D7EBF5']
        export const scope1CategoriesCompare = ['#006192', '#23759E', '#4C8AA9', '#78A0B5', '#ABBAC0 ']

        export const scope2Categories = ['#00874B', '#4DBA8A']
        export const scope2CategoriesCompare = ['#007540', '#449973']

        export const scope1CategoriesDetail = [
            '#006FAA',
            '#157BB3',
            '#2789BC',
            '#3C97C5',
            '#54A6CE',
            '#71B6D8',
            '#8FC7E1',
            '#B1D8EB',
            '#D7EBF5',
            '#D0D0D0',
        ]
        export const scope2CategoriesDetail = [
            '#00874B',
            '#129459',
            '#21A067',
            '#35AD78',
            '#4DBA8A',
            '#67C99D',
            '#86D6B2',
            '#ABE3CA',
            '#D2F1E4',
            '#D0D0D0',
        ]

        export const scope3Categories = [
            '#B81B32',
            '#CD4255',
            '#D55768',
            '#DC6D7D',
            '#E28893',

            '#E9A2AC',
            '#F1BFC6',
            '#F8DEE1',
            '#882267',
            '#952F73',

            '#A13F81',
            '#AE5190',
            '#BB67A0',
            '#C981B0',
            '#D69CC3',
        ]

        export const scope3CategoriesCompare = [
            '#9F1D2F',
            '#A32D3D',
            '#A83D4B',
            '#AC4F5B',
            '#B0616B',

            '#B5737C',
            '#B9878E',
            '#BD9A9F',
            '#7F2B67',
            '#7F2B67',

            '#883A72',
            '#91487C',
            '#9A5A87',
            '#A26D93',
            '#AB819F',
        ]
        export const scope3FirstCategories = [
            '#C01F36',
            '#C63044',
            '#CD4255',
            '#D55768',
            '#DC6D7D',

            '#E28893',
            '#E9A2AC',
            '#F1BFC6',
            '#F8DEE1',
            '#D0D0D0',
        ]
        export const scope3SecondCategories = [
            '#882267',
            '#952F73',
            '#A13F81',
            '#AE5190',
            '#BB67A0',

            '#C981B0',
            '#D69CC3',
            '#E3B9D6',
            '#F1DAEB',
            '#D0D0D0',
        ]

        export const sites = [
            '#DE6316',
            '#E58611',
            '#EDAC39',
            '#F1BF5C',
            '#F3CA73',
            '#F6D38A',
            '#F8DFA5',
            '#FAE9C2',
            '#FDF3DF',
            '#D0D0D0',
        ]

        export const year = [
            'rgb(230, 161, 161)',
            'rgb(230, 196, 161)',
            'rgb(230, 230, 161)',
            'rgb(201, 230, 161)',
            'rgb(161, 230, 178)',

            'rgb(161, 230, 230)',
            'rgb(161, 207, 230)',
            'rgb(161, 181, 230)',
            'rgb(207, 161, 230)',
            'rgb(230, 161, 201)',
        ]

        export const other = 'rgb(112, 112, 112)'
    }

    export namespace Labels {
        export const scope3Catagories = [
            '1. 購入した商品・サービス',
            '2. 資本材',
            '3. エネルギー関連活動',
            '4. 輸送、配送（上流）',
            '5. 企業から出る廃棄物',
            '6. 出張',
            '7. 雇用者の通勤',
            '8. リース資産（上流）',
            '9. 輸送、配送（下流）',
            '10. 販売した製品の加工',
        ]
    }
}
