import { WIDTH } from '@/constant/mediaQuery'
import { useMediaQuery } from '@material-ui/core'

function useCustomMediaQuery() {
    const matches1850 = useMediaQuery(WIDTH.MAX_1850)
    const matches1800 = useMediaQuery(WIDTH.MAX_1800)
    const matches1700 = useMediaQuery(WIDTH.MAX_1700)
    const matches1600 = useMediaQuery(WIDTH.MAX_1600)
    const matches1400 = useMediaQuery(WIDTH.MAX_1400)
    const matches1300 = useMediaQuery(WIDTH.MAX_1300)
    const matches1280 = useMediaQuery(WIDTH.MAX_1280)

    return { matches1280, matches1300, matches1400, matches1600, matches1700, matches1800, matches1850 }
}

export default useCustomMediaQuery
