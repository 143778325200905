import { TooltipProps } from '@material-ui/core'
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { CustomTooltip } from '../CustomTooltip'

export const OverflowTip = ({
    children,
    styles,
    className,
    styleTooltip,
    placement = 'left-start',
}: {
    children: ReactNode
    styles?: CSSProperties
    className?: string
    styleTooltip?: CSSProperties
    placement?: TooltipProps['placement']
}) => {
    const [isOverflowed, setIsOverflow] = useState(false)
    const textElementRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (textElementRef.current)
            setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth)
    }, [children])

    return (
        <CustomTooltip
            title={children as NonNullable<ReactNode>}
            disableHoverListener={!isOverflowed}
            placement={placement}
            styleTooltip={styleTooltip}
        >
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...styles,
                }}
                className={className}
            >
                {children}
            </div>
        </CustomTooltip>
    )
}
