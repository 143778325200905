import { Year } from '@/ghgApi'
import useAnalysis, { Mode } from '@/hooks/useAnalysis'
import useCustomMediaQuery from '@/hooks/useCustomMediaQuery'
import { getTime, nendo } from '@/utils/nendo'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import { Chart, ChartDataset, ChartOptions, LegendItem } from 'chart.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { ChartProps } from 'react-chartjs-2/dist/types'
import { GettingChartDataMessage } from '..'
import AnalysisLegends from '../Legend/analysisLegends'
import { DefaultStackedBarLegend } from './legend'

export type GhgBarDataset = Pick<ChartDataset<'bar', Array<number>>, 'label' | 'data' | 'backgroundColor' | 'stack'>
export type GhgStackedBarProps = Omit<ChartProps, 'type' | 'data'> & {
    datasets?: Array<GhgBarDataset>
    labels?: Array<string>
    getLegendItems?: (chart: Chart) => void
    noLegend?: boolean
    options?: ChartOptions<'bar'>
    isFixedLegend?: boolean
    isReverse?: boolean
    isPrinting?: boolean
}

export const calcularMonth = (startMonth: number, year?: number) => {
    const { year: currYear } = getTime()
    const currentYear: Year = year || currYear
    const monthArr: Array<{ month: number; year: number }> = []
    for (let i = startMonth; i < 12 + startMonth; i++) {
        if (i > 12) {
            monthArr.push({
                month: i - 12,
                year: currentYear + 1,
            })
        } else {
            monthArr.push({
                month: i,
                year: currentYear,
            })
        }
    }
    return monthArr
}

export const calcularLabelBar = (
    data: Array<{ month: number; year: number }>,
    mode: Mode,
    value?: number | null,
): Array<{ month: number; year: number }> => {
    switch (mode) {
        case 'half': {
            const half = Math.ceil(data.length / 2)
            if (value === 1) {
                return data.slice(0, half)
            } else return data.slice(half)
        }
        case 'quarter': {
            const size = Math.ceil(data.length / 4)
            let chunked: Array<Array<{ month: number; year: number }>> = []
            Array.from({ length: Math.ceil(data.length / size) }, (val, i) => {
                chunked.push(data.slice(i * size, i * size + size))
            })
            if (value === 1) return chunked[0]
            if (value === 2) return chunked[1]
            if (value === 3) return chunked[2]
            if (value === 4) return chunked[3]
        }
        default: {
            return data
        }
    }
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        columnGap: 10,
    },
    containerChart: {
        width: '80%',
        height: '335px',
    },
    fullSize: {
        width: '100%',
    },
    legendHorizontal: {
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
    },
    legendVertical: {
        width: 150,
        display: 'flex',
        alignItems: 'center',
    },
})
const GhgStackedBar = (props: GhgStackedBarProps) => {
    const { storeState, analysisState } = useStore()
    const { checkMode } = useAnalysis()
    const mode = checkMode()
    const { matches1400 } = useCustomMediaQuery()

    // 凡例項目を抽出するためのcallback
    // https://github.com/reactchartjs/react-chartjs-2/issues/81#issuecomment-546125187

    const labels = useMemo(() => {
        const currentMonthArr = calcularMonth(storeState.organization?.startMonth || 1, analysisState.date.year)
        return calcularLabelBar(currentMonthArr, mode, analysisState.date.half || analysisState.date.quarter)
    }, [mode, analysisState.date.half, analysisState.date.quarter, analysisState.date.year])

    const defaults: { labels: Array<any>; options: ChartOptions<'bar'> } = {
        labels: labels.map((item, index) => {
            if (index === 0 || item.month === 1) return [`${item.month}月`, `${item.year}年`]
            else return [`${item.month}月`]
        }),
        options: {
            //@ts-ignore
            // 有効なオプションであるのに関わらずtypeエラーになってます。
            barThickness: matches1400 ? 30 : 40,
            inflateAmount: 0,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    itemSort(a, b) {
                        if (props.isReverse) return b.datasetIndex - a.datasetIndex
                        return a.datasetIndex - b.datasetIndex
                    },
                    callbacks: {
                        label: (context) => {
                            const dataset = context.dataset
                            if (dataset) {
                                const dataIndex = context.dataset.data[context.dataIndex]
                                const quantity = dataIndex ? dataIndex.toFixed(3) : dataIndex
                                return `${dataset.label}: ${quantity}`
                            }
                            return ''
                        },
                    },
                },
            },
            barValueSpacing: 20,
            interaction: {
                mode: 'index' as const,
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        },
    }

    const [legendItems, setLegendItems] = useState<Array<LegendItem>>()
    const chartRef = useCallback((chart: Chart) => {
        if (chart) {
            //@ts-ignore
            setLegendItems(chart.legend.legendItems?.reverse())
        }
    }, [])

    const { matches1700 } = useCustomMediaQuery()
    const classes = useStyles()

    if (props.datasets) {
        return (
            <div className={matches1700 ? '' : classes.container}>
                <div className={`${classes.containerChart} ${matches1700 ? classes.fullSize : ''}`}>
                    <div>排出量（単位：t-CO&#8322;e）</div>
                    <Bar
                        //@ts-ignore
                        ref={props.noLegend ? props.getLegendItems : chartRef}
                        type="Bar"
                        data={{
                            labels: props.labels ? props.labels : defaults.labels,
                            datasets: props.datasets,
                        }}
                        options={{ ...defaults.options, ...props.options }}
                        width="100%"
                    />
                </div>
                {!props.noLegend &&
                    legendItems &&
                    (props.isFixedLegend && matches1700 ? (
                        <div style={{ marginTop: 30 }}>
                            <AnalysisLegends legendItems={legendItems} />
                        </div>
                    ) : (
                        <div className={matches1700 ? classes.legendHorizontal : classes.legendVertical}>
                            <DefaultStackedBarLegend legendItems={legendItems} />
                        </div>
                    ))}
            </div>
        )
    } else {
        return <GettingChartDataMessage />
    }
}

export default GhgStackedBar
