import React from 'react'
import { TDirection } from './ExpandIcon'

export default function ArrowExpandIcon({ direction = 'left' }: { direction?: TDirection }) {
    return (
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     xmlnsXlink="http://www.w3.org/1999/xlink"
        //     width="35"
        //     height="35"
        //     viewBox="0 0 35 35"
        //     style={{
        //         rotate: direction === 'right' ? '180deg' : '0deg',
        //         transition: 'rotate 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        //     }}
        // >
        //     <defs>
        //         <filter id="Ellipse_59" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse">
        //             <feOffset dx="2" dy="2" in="SourceAlpha" />
        //             <feGaussianBlur stdDeviation="2" result="blur" />
        //             <feFlood floodOpacity="0.161" />
        //             <feComposite operator="in" in2="blur" />
        //             <feComposite in="SourceGraphic" />
        //         </filter>
        //     </defs>
        //     <g id="Group_635" data-name="Group 635" transform="translate(-236 -139)">
        //         <g transform="matrix(1, 0, 0, 1, 236, 139)" filter="url(#Ellipse_59)">
        //             <g
        //                 id="Ellipse_59-2"
        //                 data-name="Ellipse 59"
        //                 transform="translate(4 4)"
        //                 fill="#42526e"
        //                 stroke="#42526e"
        //                 strokeWidth="2"
        //             >
        //                 <circle cx="14" cy="14" r="14" stroke="none" />
        //                 <circle cx="14" cy="14" r="13" fill="none" />
        //             </g>
        //         </g>
        //         <line
        //             id="Line_239"
        //             data-name="Line 239"
        //             x2="12"
        //             transform="translate(248 157)"
        //             fill="none"
        //             stroke="#f4faff"
        //             strokeLinecap="round"
        //             strokeWidth="2"
        //         />
        //         <line
        //             id="Line_240"
        //             data-name="Line 240"
        //             y1="6"
        //             x2="6"
        //             transform="translate(248 151)"
        //             fill="none"
        //             stroke="#f4faff"
        //             strokeLinecap="round"
        //             strokeWidth="2"
        //         />
        //         <line
        //             id="Line_241"
        //             data-name="Line 241"
        //             x2="6"
        //             y2="6"
        //             transform="translate(248 157)"
        //             fill="none"
        //             stroke="#f4faff"
        //             strokeLinecap="round"
        //             strokeWidth="2"
        //         />
        //     </g>
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
            style={{
                rotate: direction === 'right' ? '180deg' : '0deg',
                transition: 'rotate 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
        >
            <path
                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                fill="#42526e"
            />
        </svg>
    )
}
