import DotIcon from '@/components/atoms/Icon/svg/DotIcon'
import { makeStyles } from '@material-ui/core'
import { LegendItem } from 'chart.js'
import React from 'react'

function AnalysisLegends({ gridRow = 5, ...props }: { legendItems: Array<LegendItem>; gridRow?: number }) {
    const useStyle = makeStyles({
        container: {
            width: 'fit-content',
            display: 'grid',
            gridTemplateRows: `repeat(${gridRow}, 1fr)`,
            gridAutoFlow: 'column',
            gap: 15,
            margin: '0 auto',
        },
        legendItem: {
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            fontSize: 14,
            fontWeight: 300,
        },
        icon: {
            minWidth: 14,
        },
    })
    const classes = useStyle()
    return (
        <div className={classes.container}>
            {props.legendItems.map((item, i) => (
                <div key={i} className={classes.legendItem}>
                    <div className={classes.icon}>
                        <DotIcon color={item.fillStyle as string} />
                    </div>
                    <span className="text-clame-1">{item.text}</span>
                </div>
            ))}
        </div>
    )
}

export default AnalysisLegends
