import { calcularMonth } from '@/components/charts/StackedBar'
import { DateSelectorMode } from '@/components/template/Analysis/Selector/DateSelector'
import { getTime } from '@/utils/nendo'
import { AnalysisState, Date as AnalysisDate } from '@/zustand/slice/analysisSlice'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import React, { SetStateAction } from 'react'

export type Mode = 'year' | 'half' | 'quarter'
export interface DMode {
    mode: Omit<Mode, 'year'> | 'month' | null
    value: number
}

export default function useAnalysis() {
    const { analysisState, setAnalysisState } = useStore()

    const setAnalysisDate = (
        type?: 'half' | 'quarter' | 'month' | 'year' | 'compare',
        value?: AnalysisDate['half'] | AnalysisDate['quarter'] | AnalysisDate['month'] | AnalysisDate['year'],
    ) => {
        const newAnalysisDateValue = Object.values(analysisState.date).map((v, i) => {
            // reset half, quater, month = null
            if (v && v?.toString().length > 2) return (v = v)
            return (v = null)
        })
        const newAnalysisDateKey = Object.keys(analysisState.date)
        const result = newAnalysisDateKey.reduce((acc, e, i) => {
            return { ...acc, [e]: newAnalysisDateValue[i] }
        }, {})

        let date = {
            ...analysisState.date,
            ...result,
        }
        const years = analysisState.dateSelector.options.years

        if (type === 'half' && value) date = { ...date, half: value }
        if (type === 'quarter' && value) date = { ...date, quarter: value }
        if (type === 'month' && value) date = { ...date, month: value }
        if (type === 'year' && value)
            date = { ...date, year: value, compareYear: value === years[0] ? years[1] : years[0] }
        if (type === 'compare' && value) date = { ...date, compareYear: value }
        setAnalysisState({
            ...analysisState,
            date: { ...date },
        })
    }

    const checkMode = () => {
        if (analysisState.date.half) return 'half'
        if (analysisState.date.quarter) return 'quarter'
        return 'year'
    }
    const checkDMode = (): DMode | null => {
        if (analysisState.date.month) return { mode: 'month', value: analysisState.date.month }
        if (analysisState.date.half) return { mode: 'half', value: analysisState.date.half }
        if (analysisState.date.quarter) return { mode: 'quater', value: analysisState.date.quarter }
        return null
    }

    const setInitialAnalysisState = (
        startMonth: number,
        storageTime?: InputDate | null,
        setStorageTime?: React.Dispatch<SetStateAction<InputDate | null>>,
    ) => {
        const { year, month } = getTime()
        const isLarger = startMonth <= month
        const currYear = isLarger ? year : year - 1
        const defaultDate = { year: currYear, half: null, quarter: null, month: null, compareYear: currYear - 1 }
        const initialDate = defaultDate
        const lengthOfYear = currYear - 2015

        const initialAnalysisState: AnalysisState = {
            isLoaded: true,
            date: initialDate,
            dateSelector: {
                mode: DateSelectorMode.YEAR,
                options: {
                    ...analysisState.dateSelector.options,
                    months: calcularMonth(startMonth, analysisState.date.year).map((i) => i.month),
                    years: Array.from({ length: lengthOfYear }, (_, i) => currYear - i),
                },
            },
        }
        if (!storageTime && setStorageTime) {
            setStorageTime({ month: startMonth, year: currYear })
        }
        setAnalysisState({ ...initialAnalysisState })
    }

    const generateTime = (startMonth: number) => {
        if (analysisState.date.half) {
            if (analysisState.date.half === 1) return '上半期'
            return '下半期'
        }
        if (analysisState.date.quarter) {
            return `${analysisState.date.quarter}Q`
        }
        if (analysisState.date.month) {
            const year = analysisState.date.month < startMonth ? analysisState.date.year + 1 : analysisState.date.year
            return `${analysisState.date.month}月-${year}`
        }
        return ''
    }

    return { setAnalysisDate, checkMode, checkDMode, setInitialAnalysisState, generateTime }
}
