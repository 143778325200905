import React from 'react'

export default function DotIcon({ color = '#42526e' }: { color?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
        >
            <circle fill={color} cx="8" cy="8" r="8" />
        </svg>
    )
}
