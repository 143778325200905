import _ from 'lodash'

export const round = function (num: number, places = 2): number {
    return _.round(num, places)
}

export const sum = function (data: Array<number>): number {
    return data.reduce((a, b) => a + b, 0)
}

export const sumOfMatrix = (array: number[][]) => {
    const result = array.reduce((r, a) => {
        a.forEach((b, i) => {
            r[i] = (r[i] || 0) + b
        })
        return r
    }, [])
    return result
}
