import DotIcon from '@/components/atoms/Icon/svg/DotIcon'
import useCustomMediaQuery from '@/hooks/useCustomMediaQuery'
import { makeStyles } from '@material-ui/core'
import { LegendItem } from 'chart.js/auto'
import React from 'react'

const useStyle = makeStyles({
    container: {
        overflow: 'auto',
        maxHeight: 290,
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            border: '1px solid #B7B7B7',
        },
        '&::-webkit-scrollbar': {
            width: 10,
            height: 10,
            backgroundColor: '#FFF',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#B7B7B7',
        },
    },
    containerResponsive: {
        display: 'flex',
        width: 'fit-content',
    },
    legendItem: {
        minWidth: '100px',
        padding: '2px 10px',
        marginTop: 5,
        fontSize: 14,
        fontWeight: 300,
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
    },
})

export const DefaultStackedBarLegend = (props: { legendItems: Array<LegendItem> | undefined }) => {
    const { matches1700 } = useCustomMediaQuery()
    const classes = useStyle()
    return (
        <div className={`${classes.container} ${matches1700 ? classes.containerResponsive : ''}`}>
            {props.legendItems &&
                props.legendItems.slice(0).map((item: LegendItem, index: number) => {
                    return (
                        <div className={classes.legendItem} key={`legend-item-${item.text}-${index}`}>
                            <DotIcon color={item.fillStyle as string} />
                            <span>{item.text}</span>
                        </div>
                    )
                })}
        </div>
    )
}
