import SelectField from '@/components/atoms/SelectField'
import useAnalysis from '@/hooks/useAnalysis'
import useStore from '@/zustand/sotre'
import React, { useMemo } from 'react'
import { SelectorProps } from './YearSelector'

function MonthSelector(props: SelectorProps) {
    const { storeState, analysisState } = useStore()
    const { setAnalysisDate } = useAnalysis()

    const { date, dateSelector } = analysisState
    const monthsOption = useMemo(() => {
        const now = new Date()
        const currentMonth = now.getMonth() + 1
        const startMonth = storeState.organization?.startMonth as number
        const largestYear = startMonth > now.getMonth() + 1 ? now.getFullYear() - 1 : now.getFullYear()

        const result = dateSelector.options.months.map((month) => {
            let value = `${month}月`
            if (month === 1 && startMonth !== 1) {
                value = `${month}月-${date.year + 1}`
            }
            return {
                id: month,
                value,
            }
        })

        if (date.year === largestYear) {
            const index = result.findIndex((x) => x.id === currentMonth)
            if (startMonth > currentMonth) result.splice(index + 1, result.length - index)
            else result.splice(index + 1, result.length - index)
        }
        return result
    }, [dateSelector.options.months, date.year, storeState.organization?.startMonth])

    function onMonthSelected(month: number) {
        setAnalysisDate('month', month)
    }

    return (
        <SelectField
            size={props.size}
            value={date.month || ''}
            onChange={(e) => onMonthSelected(e.target.value as number)}
            menuItems={monthsOption}
            placeholder="月度選択"
        />
    )
}

export default MonthSelector
