import Helmet from '@/components/molecules/Helmet'
import theme from '@/theme'
import React, { useEffect, useState } from 'react'
// @ts-ignore
import SimpleSnackbar from '@/components/atoms/Snackbar'
import { checkAccessUser } from '@/services/auth'
import cache from '@/utils/cache'
import { createStyles, CssBaseline, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { navigate } from 'gatsby'

import { Footer } from '@/components/molecules/Footer'
import Header from '@/components/molecules/Header'
import MenuBar from '@/components/molecules/Menu'
import useAnalysis from '@/hooks/useAnalysis'
import { ROUTES } from '@/routes'
import customTheme from '@/theme'
import { initialValueStoreState } from '@/zustand/slice/storeSlice'
import useStore from '@/zustand/sotre'
import _ from 'lodash'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { InputDate } from '@/zustand/slice/inputSlice'
import { Auth } from '@aws-amplify/auth'

const drawerWidth = 256

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflow: 'auto',
        },
        flexCenter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        root: {
            display: 'flex',
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        contentWrapper: {
            paddingTop: 94,
            flexGrow: 1,
            padding: theme.spacing(3),
            marginLeft: 72,
            transition: 'margin-left 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            backgroundColor: customTheme.colors.white,
            minWidth: `calc(1280px - ${drawerWidth}px - 25px)`,
        },
        content: {
            minHeight: 'calc(100vh - 240px)',
        },
        contentExpanded: {
            marginLeft: drawerWidth,
        },
    }),
)

const Layout = ({ children }: { children: React.ReactNode }) => {
    const path = typeof window !== 'undefined' ? window.location.pathname : ''
    const [isLogedin, setIsLogedin] = useState(!!cache.get('access_token') || false)
    const checkTabExpanded = () => {
        const idsArr: number[] = []
        if (path === ROUTES.HOME) idsArr.push(1)
        if (path.includes('input')) idsArr.push(2)
        if (path.includes('analysis')) idsArr.push(3)
        if (path.includes('reporting')) idsArr.push(4)
        if (path.includes('management')) idsArr.push(5)
        return idsArr
    }
    const initialTabId = checkTabExpanded()
    const [expandedMenuId, setExpandedMenuId] = useState<number[]>(initialTabId)
    const {
        fetchStoreData,
        storeState,
        analysisState,
        fetchEmissionFactorTableNodes,
        expandedMenu: open,
        setExpandedMenu: setOpen,
    } = useStore()
    const { setInitialAnalysisState } = useAnalysis()
    const [storageTime, setStorageTime] = useStateWithSessionStorage<InputDate | null>(
        SessionStorageKey.DATA_INPUT_DATE,
        null,
    )

    const classes = useStyles()

    useEffect(() => {
        let now = new Date()
        let endDate = new Date("2024-01-19 19:00:00")
        console.log("now", now)
        console.log("endDate", endDate)
        console.log(now < endDate)
        if (now < endDate) {
            navigate(ROUTES.MAINTENANCE)
            return
        }

        const checkAccess = async () => {
            const isAccess = await checkAccessUser()
            if (!isAccess) return navigate(ROUTES.LOGIN)
            setIsLogedin(true)
            if (_.isEqual(storeState, initialValueStoreState)) {
                fetchStoreData()
                fetchEmissionFactorTableNodes()
            }
        }
        checkAccess()
    }, [])

    useEffect(() => {
        if (_.isEqual(storeState, initialValueStoreState)) return
        checkMfaSettings()
        if (!analysisState.isLoaded && storeState.organization?.startMonth) {
            setInitialAnalysisState(storeState.organization.startMonth, storageTime, setStorageTime)
            return
        }
    }, [storeState])

    const checkMfaSettings = async () => {
        if (!storeState.organization?.forcedMfa) {
            return
        }
        const preferredMfa = await getPreferredMfa()
        if (!preferredMfa || preferredMfa === 'NOMFA') {
            navigate(ROUTES.MFA_DEVICE_REGISTRATION_FORCE)
        }
    }

    const getPreferredMfa = async () => {
        const user = await Auth.currentAuthenticatedUser();
        return await Auth.getPreferredMFA(user);
    }

    const handleDrawerOpen = () => {
        setOpen(true)
        setExpandedMenuId(initialTabId)
    }
    const handleDrawerClose = () => {
        setOpen(false)
        setExpandedMenuId([])
    }
    const handleTabClick = (p?: string, id?: number) => {
        if (p && id && (id == 7 || id == 8)) {
            window.open(p)
            return
        }
        if (p) return navigate(p)
        if (id && !expandedMenuId.includes(id)) {
            if (!open) handleDrawerOpen()
            setExpandedMenuId((pre) => [...pre, id])
            return
        }
        if (id && expandedMenuId.includes(id))
            return setExpandedMenuId((pre) => {
                const newIds = pre.filter((preId) => preId !== id)
                return newIds
            })
    }

    if (isLogedin)
        return (
            <div className={classes.container}>
                <Helmet />
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Header open={open} drawerWidth={drawerWidth} />
                        <MenuBar
                            open={open}
                            expandedMenuId={expandedMenuId}
                            initialTabId={initialTabId}
                            drawerWidth={drawerWidth}
                            handleDrawerClose={handleDrawerClose}
                            handleDrawerOpen={handleDrawerOpen}
                            handleTabClick={handleTabClick}
                        />
                        <main id="main" className={`${classes.contentWrapper} ${open ? classes.contentExpanded : ''}`}>
                            <div className={classes.content}>{children}</div>
                            <Footer isShrink />
                        </main>
                    </ThemeProvider>
                </MuiThemeProvider>
            </div>
        )
    return null
}

export default Layout

export function withLayout(Content: () => JSX.Element) {
    return function () {
        return (
            <Layout>
                {<Content />}
                <SimpleSnackbar />
            </Layout>
        )
    }
}
