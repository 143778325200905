import { SETTING_OPTIONS } from '@/constant/options'
import theme from '@/theme'
import { Box, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { navigate } from 'gatsby'
import React from 'react'

const useStyle = makeStyles({
    container: {
        width: 150,
        backgroundColor: theme.colors.white,
    },
    listItemRoot: {
        paddingBottom: 0,
        paddingTop: 0,
    },
})

export default function SettingOptions() {
    const classes = useStyle()

    return (
        <Box boxShadow={3} className={classes.container}>
            <List component="nav" aria-label="main mailbox folders">
                {SETTING_OPTIONS.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        classes={{ root: classes.listItemRoot }}
                        onClick={() => navigate(item.path)}
                    >
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}
